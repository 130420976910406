<template>
  <v-row class="app-invoice-preview">
    <v-col
      cols="12"
      md="9"
    >
      <embed
        v-if="pdfBlobUrl"
        :src="pdfBlobUrl"
        width="100%"
        height="375"
        type="application/pdf"
      >
    </v-col>

    <!-- Right Column: Invoice Action -->
    <v-col
      cols="12"
      md="3"
    >
      <v-card>
        <v-card-text>
          <!-- <v-btn
            color="primary"
            class="mb-3"
            block
            @click="isInvoiceSendSidebarActive = true"
          >
            <v-icon
              class="me-2"
              left
            >
              {{ icons.mdiSendOutline }}
            </v-icon>
            <span>Send Invoice</span>
          </v-btn> -->
          <v-btn
            class="mb-3"
            color="secondary"
            block
            outlined
            @click="downloadInvoice"
          >
            Download
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import { mdiSendOutline, mdiCurrencyUsd } from '@mdi/js'
import { downloadCheckoutReceiptPDF, fetchCheckoutReceipts } from '@api/order'

export default {
  setup() {
    const receiptData = ref(null)
    let pdfData = null
    const pdfBlobUrl = ref(null)

    fetchCheckoutReceipts(router.currentRoute.params.id)
      .then(({ data }) => {
        // const { records, pagination } = data.data
        // receiptData.value = records
        // paymentDetails.value = records[0]
      })
      .catch(error => {
        if (error.response.status === 404) {
          receiptData.value = undefined
        }
      })

    downloadCheckoutReceiptPDF(router.currentRoute.params.id).then(({ data }) => {
      pdfData = data
      pdfBlobUrl.value = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
    })

    const downloadInvoice = () => {
      window.open(URL.createObjectURL(pdfData))
    }

    return {
      pdfBlobUrl,
      receiptData,

      // invoiceDescription,
      downloadInvoice,

      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

@media print {
  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
